
<ng-container>
  <div>

    <main>
      <section class="welcome">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="left">
                <h1>
                  <span > {{ 'main_head_p1' | L }} </span>
                  <span > {{ 'main_head_p2' | L }} </span>

                </h1>
                <p [class.MainSpainParg]="true">
                  {{ 'main_head_p3' | L }}
                </p>
                <div class="button">
                  <a href="#" class="primary">{{ 'orderService' | L }}</a>
                  <a href="#" class="second">{{ 'contactUs' | L }}</a>
                </div>
                <div class="cubes">
                  <span class="left-top cube-floating delay"></span>
                  <span class="left-middle cube-floating"></span>
                  <span class="left-bottom cube-floating delay"></span>
                  <span class="right-top cube-floating"></span>
                  <span class="right-bottom cube-floating delay"></span>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">

              <div class="animation">
                <div class="peoples">
                  <span class="people-ma-four s-group"></span>
                  <span class="people-ma-three s-group"></span>

                  <span class="people-fe-two o-group"></span>
                  <span class="people-ma-two o-group"></span>
                  <span class="people-fe-three o-group"></span>
                  <span class="desk o-group"></span>
                  <span class="graph-two o-group"></span>

                  <span class="graph-one l-group"></span>
                  <span class="people-ma-one l-group"></span>
                  <span class="people-fe-one l-group"></span>
                </div>
                <div class="blocks">
                  <span class="right"></span>
                  <span class="middle"></span>
                  <span class="left-o"></span>
                  <span class="left-l"></span>
                  <span class="left-s"></span>
                  <span class="l-t-cube"></span>
                  <span class="sm-light l-group"></span>
                  <span class="sm-cube down"></span>
                  <span class="dr-cube"></span>
                </div>
                <div class="cube">
                  <span class="box"></span>
                  <span class="box-top delay-hidden"></span>
                  <span class="box-light floating-second delay"></span>
                  <span class="box-cubes"></span>
                  <span class="box-left-stats"></span>
                  <span class="box-right-stats"></span>
                </div>
              </div>
              <!--<div class="right">
                <div class="right-cube floating-one"></div>
                <div class="left-cube floating-one"></div>
                <div class="top-cube floating-second delay"></div>
                <div class="light floating-second"></div>
                <div class="cube floating-second"></div>
                <div class="background floating"></div>
              </div>-->
            </div>
          </div>

        </div>
      </section>

      <section class="featured-boxes">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <div class="item">
                <div class="icon dedicated"><i></i></div>
                <div class="head">{{ 'DedicatedTitle' | L }}</div>
                <span class="description">{{ 'DedicatedText' | L }}</span>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="item">
                <div class="icon development"><i></i></div>
                <div class="head">{{ 'DevelopmentTitle' | L }}</div>
                <span class="description">{{ 'DevelopmentText' | L }}</span>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="item">
                <div class="icon branding"><i></i></div>
                <div class="head">{{ 'BrandingTitle' | L }}</div>
                <span class="description">{{ 'BrandingText' | L }}</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="this-is-rubikly">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="left">
                <div class="splash"></div>
                <div class="box"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="right">
                <h2>{{ 'ThisisRubiklyTitle' | L }}</h2>
                <p> {{ 'ThisisRubiklyText' | L}}</p>
                <div class="button"><a href="#">{{ 'thisIsRubiklyBtn' | L }}</a></div>
                <div class="stats">
                  <ul>
                    <li>
                      <span>10+</span>
                      <span>{{ 'thisIsRubiklyCounter3' | L }}</span>
                    </li>
                    <li>
                      <span>100+</span>
                      <span>{{ 'thisIsRubiklyCounter2' | L }}</span>
                    </li>
                    <li>
                      <span>46</span>
                      <span>{{ 'thisIsRubiklyCounter1' | L }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="our-services">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="cubes">
                <span class="middle-left cube-floating-one"></span>
                <span class="middle-right cube-floating-second"></span>

                <span class="left-top cube-floating-one"></span>
                <span class="left-bottom cube-floating-second"></span>

                <span class="right-top cube-floating-second"></span>
                <span class="right-bottom cube-floating-one"></span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="main-head">
                <div class="bg-text">{{ 'Rubikly' | L }}</div>
                <h2>{{ 'OurServices' | L }}</h2>
                <div class="sub-title">{{'OurServicesSubTitle' | L}}</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="primary-featured">
                <div class="icon left"><i></i></div>
                <div class="head">{{ 'Service1Title' | L}}</div>
                <span class="description">{{'service1Text' | L}}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="primary-featured">
                <div class="icon right"><i></i></div>
                <div class="head">{{ 'Service2Title' | L}}</div>
                <span class="description">{{'Service2Text' | L}}</span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="all-features">
                <div class="head">{{ 'AllFeaturesHead' | L}}</div>
              </div>
            </div>
            <div class="col-4 col-md-2">
              <div class="features-item">
                <div class="box">
                  <div class="content">
                    <div class="white"><i class="server"></i></div>
                  </div>
                  <div class="name">{{ 'Feature1' | L }}</div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-2">
              <div class="features-item">
                <div class="box">
                  <div class="content">
                    <div class="white"><i class="search"></i></div>
                  </div>
                  <div class="name">{{ 'Feature2' | L }}</div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-2">
              <div class="features-item">
                <div class="box">
                  <div class="content">
                    <div class="white"><i class="role"></i></div>
                  </div>
                  <div class="name">{{ 'Feature3' | L }}</div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-2">
              <div class="features-item">
                <div class="box">
                  <div class="content">
                    <div class="white"><i class="world"></i></div>
                  </div>
                  <div class="name">{{ 'Feature4' | L }}</div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-2">
              <div class="features-item">
                <div class="box">
                  <div class="content">
                    <div class="white"><i class="group"></i></div>
                  </div>
                  <div class="name">{{ 'Feature5' | L }}</div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-2">
              <div class="features-item">
                <div class="box">
                  <div class="content">
                    <div class="white"><i class="wreath"></i></div>
                  </div>
                  <div class="name">{{ 'Feature6' | L }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="why-rubikly">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="cubes">
                <span class="left cube-floating-one"></span>
                <span class="right cube-floating-second"></span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="main-head">
                <div class="bg-text">{{ 'Rubikly' | L }}</div>
                <h2>{{ 'PurpleSectionTitle' | L }}</h2>
                <div class="sub-title">{{'PurpleSectionSubTitle' | L}}</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="item">
                <div class="head">{{'PurpleSectionSection1Title' | L}}</div>
                <span class="description">{{'PurpleSectionSection1Text' | L}}</span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="item">
                <div class="head">{{'PurpleSectionSection2Title' | L}}</div>
                <span class="description">{{'PurpleSectionSection2Text' | L}}</span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="item">
                <div class="head">{{'PurpleSectionSection3Title' | L}}</div>
                <span class="description">{{'PurpleSectionSection3Text' | L}}</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="creative-boxes">
        <div class="container">

          <div class="row">
            <div class="col-md-12 col-lg-6 col-xl-5">

              <div class="left">
                <div class="small-head">{{ 'WhatWeDo' | L }}</div>
                <div class="primary-head">{{ 'WhatWeDoTitle' | L }}</div>
                <div class="button"><a href="#">{{ 'WhatWeDoBtn' | L }}</a></div>
              </div>
            </div>
            <div class="col-md-12 col-lg-3">
              <div class="boxes o-l">
                <div class="item pink">
                  <div class="icon"><div class="circle"><i class="icon-affiliate"></i></div></div>
                  <div class="head">{{ 'WhatWeDoFeature1Title' | L }}</div>
                </div>
                <div class="item purple">
                  <div class="icon"><div class="circle"><i class="icon-graphic-design"></i></div></div>
                  <div class="head">{{ 'WhatWeDoFeature2Title' | L }}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-3">
              <div class="boxes o-r">
                <div class="item blue">
                  <div class="icon"><div class="circle"><i class="icon-coding"></i></div></div>
                  <div class="head">{{ 'WhatWeDoFeature3Title' | L }}</div>
                </div>
                <div class="item green">
                  <div class="icon"><div class="circle"><i class="icon-idea"></i></div></div>
                  <div class="head">{{ 'WhatWeDoFeature4Title' | L }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

<!--      <section class="slider-works">-->
<!--&lt;!&ndash;        <div class="owl-carousel">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="slider">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="item"><img src="assets/img/slider-works/one.png"></div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="item"><img src="assets/img/slider-works/second.png"></div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="item"><img src="assets/img/slider-works/other.png"></div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="item"><img src="assets/img/slider-works/last.png"></div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </section>-->

      <section class="build-future">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="head">{{ 'BuildYourFutureTitle' | L }}</div>
            </div>
            <div class="col-12 col-lg-7"><p>{{ 'BuildYourFutureText' | L }}</p></div>
            <div class="col-12 col-lg-2"><div class="button"><a href="#">{{'contactUs' | L}}</a></div></div>
          </div>
        </div>
      </section>
    </main>

  </div>
</ng-container>
