import { Pipe, PipeTransform } from '@angular/core';
import {TerminologyService} from "./servies/TerminologyService";

@Pipe({
  name: 'L',
  pure: false  // Impure pipe, update when we get new data
})
export class LocalizationPipe implements PipeTransform {
  private currentTerms: { [key: string]: string } = {};

  constructor(private terminologyService: TerminologyService) {
    this.terminologyService.currentTerminologies.subscribe(terms => {
      this.currentTerms = terms;
    });
  }

  transform(value: string): string {
    return this.currentTerms[value] || value;
  }
}
