import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";

@Component({
  selector: 'app-home-nav',
  templateUrl: './home-nav.component.html',
  styleUrls: ['./home-nav.component.css'],
})
export class HomeNavComponent {

  currentLang = 'en';
  isHome = true;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.isHome =  this.activatedRoute.snapshot.firstChild?.url[0]?.path ? false : true;
    });
  }


  checngeLang(en: string) {
    this.currentLang = en;
  }




  gotToFreeQuoteSection() {
    const element = document.querySelector("#quote")
    if (element) element.scrollIntoView({behavior: 'smooth', block: 'start'})

  }
}
