<ng-container>
  <div>

    <main>
      <section class="breadcrumb full">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h1>Portfolio</h1>
              <ul class="nav">
                <li><a routerLink="/">Home</a></li>
                <li>Portfolio</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="portfolio">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="main-head">Our Big Projects</div>
              <div class="desc">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</div>
            </div>
            <div class="col-md-12">
              <div class="tab-con">
                <div class="tab active">Tab #1</div>
                <div class="tab">Tab #2</div>
                <div class="tab">Tab #3</div>
                <div class="tab">Tab #4</div>
                <div class="tab">Tab #5</div>
              </div>
              <div class="tab-content" id="tab-1" style="display: block;">
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1001.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1002.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1003.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1004.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1005.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1006.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1007.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1008.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1009.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1010.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1011.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1012.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-content" id="tab-2" style="display: none;">
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1008.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1009.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1010.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1012.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-content" id="tab-3" style="display: none;">
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1003.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1008.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1002.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1001.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-content" id="tab-4" style="display: none;">
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1008.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1009.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1010.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1011.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1012.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-content" id="tab-5" style="display: none;">
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1003.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1008.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1002.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="item">
                      <div class="image">
                        <img src="assets/img/portfolio/1001.png">
                      </div>
                      <div class="data">
                        <div class="content">
                          <div class="head">Box Portfolio Head</div>
                          <div class="category">Sub Category</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="build-future">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="head">Build your Future!</div>
            </div>
            <div class="col-12 col-lg-7"><p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it.</p></div>
            <div class="col-12 col-lg-2"><div class="button"><a href="#">CTA Button</a></div></div>
          </div>
        </div>
      </section>

    </main>






  </div>
</ng-container>
