import {Component} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
   encapsulate: boolean;

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const path = event.url;
      const result = this.checkPath(path);
      console.log(result);
      this.encapsulate = result;
    });
  }

  private checkPath(path: string): boolean {
    const targetPaths = ['admin', 'login', 'register'];
    const cleanedPath = path.split('/')[1];  // Assuming the path starts with a slash
    return targetPaths.includes(cleanedPath);
  }

}
