import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import {HttpClient} from "@angular/common/http";
import {catchError, map, Observable, of, throwError} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;

    constructor(private router: Router, private toastr: ToastrService , private httpClient: HttpClient) {}

   async  loginByAuth({email, password}) {
       console.log(email,password);
       let url = 'http://127.0.0.1:8000/api/login';
        this.httpClient.post(url , {email , password}).subscribe((res:any) =>{
         console.log(res)
          this.toastr.success("login successful");
          localStorage.setItem('token', res.token)
          this.router.navigate(['/admin']);
       } , error => {
          console.log(error.error.message)
          this.toastr.error(error.error.message);
        });



    }

    async registerByAuth({email, password}) {
        try {
            const token = await Gatekeeper.registerByAuth(email, password);
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }



    async registerByGoogle() {
        try {
            const token = await Gatekeeper.registerByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }



    async registerByFacebook() {
        try {
            const token = await Gatekeeper.registerByFacebook();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

      getProfile(): Observable<any> {
        let url = 'http://127.0.0.1:8000/api/profile';
        return this.httpClient.get(url).pipe(
          catchError(() => {
            return of(null); // Return null in case of an error
          })
        );
      }


    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/login']);
    }
}
