import { Component } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent {
  loading = true;

  constructor() {
    setTimeout(()=>{
      this.loading = false;
    } , 3000)

  }

}
