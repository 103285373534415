import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { DialogService } from "primeng/dynamicdialog";

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.scss'],
})
export class TermComponent implements OnInit {
  pagesData: any[] = [];
  displayEditDialog: boolean = false;
  selectedItem: any = {};
  newItem: any = {};
  displayCreateDialog: boolean = false;

  constructor(private http: HttpClient, private dialogService: DialogService) {}

  ngOnInit(): void {
    this.fetchPagesData();
  }

  fetchPagesData(): void {
    const url = 'http://127.0.0.1:8000/api/pagesData';
    this.http.get(url).subscribe((data: any[]) => {
      this.pagesData = data;
    });
  }

  openEditDialog(item: any, field: string): void {
    console.log(item , field)
    if (item && item[field]) {
      this.selectedItem = { ...item, editedField: field, editedValue: item[field] };
      console.log(this.selectedItem);
      this.displayEditDialog = true;
    } else {
      console.error("Invalid item or field.");
    }
  }

  saveEdit(): void {
    // Update backend and update pagesData
    const url = `http://127.0.0.1:8000/api/pagesData/${this.selectedItem.id}`;
    const updatePayload = this.selectedItem ;
    this.selectedItem.editedField = this.selectedItem.key;
    this.selectedItem.value = this.selectedItem.editedValue;
    this.http.put(url, updatePayload).subscribe(() => {
      this.fetchPagesData();
      this.displayEditDialog = false;
    });
  }

  cancelEdit(): void {
    this.displayEditDialog = false;
  }

  openCreateDialog(): void {
    this.newItem = {};
    this.displayCreateDialog = true;
  }

  saveCreate(): void {
    // Send the newItem to the backend to create a new record
    const url = 'http://127.0.0.1:8000/api/pagesData';

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = JSON.stringify(this.newItem);

    this.http.post(url, body, { headers }).subscribe(() => {
      this.fetchPagesData();
      this.displayCreateDialog = false;
    });

  }

  cancelCreate(): void {
    this.displayCreateDialog = false;
  }

  deleteRecord(id) {
    const url = `http://127.0.0.1:8000/api/pagesData/${id}`;

    this.http.delete(url).subscribe(() => {
      this.fetchPagesData(); // Refresh the data after deletion
    });

  }
}
