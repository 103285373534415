<ng-container>
  <footer>
    <div id="quote" class="get-free-quote">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="main-head">
              <div class="bg-text">{{ 'FooterBackgroundText' | L}}</div>
              <h2>{{ 'GetFreeQuoteTitle' | L }}</h2>
              <div class="sub-title">{{ 'GetFreeQuoteText' | L }}</div>
            </div>
          </div>
          <div class="col-md-12 col-lg-3">
            <div class="input">
              <input type="text" placeholder="Name">
            </div>
          </div>
          <div class="col-md-12 col-lg-3">
            <div class="input">
              <input type="text" placeholder="Email">
            </div>
          </div>
          <div class="col-md-12 col-lg-3">
            <div class="dropdown">
              <select>
                <option disabled selected>Select Subject</option>
                <option>Category One</option>
                <option>Category Second</option>
                <option>Category Last</option>
              </select>
            </div>
          </div>
          <div class="col-md-12 col-lg-3">
            <div class="button">
              <button>{{ 'GetFreeQuoteBtn' | L }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-md-3"><div class="logo"><a href="#"></a></div></div>
          <div class="col-md-6"><span>© 2023 RUBIKLY. All rights reserved | <a href="mailto:info@rubikly.io">info@rubikly.io</a></span></div>
          <div class="col-md-3">
            <ul>
              <li><a href="#"><i class="icn icon-facebook"></i></a></li>
              <li><a href="#"><i class="icn icon-twitter"></i></a></li>
              <li><a href="#"><i class="icn icon-instagram"></i></a></li>
              <li><a href="#"><i class="icn icon-linkedin"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>

</ng-container>
