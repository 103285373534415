<header [class.white]="!isHome">
  <div class="container ">
    <div class="row">
      <div class="col-md-12">
        <div class="top-func " [class.home]="isHome">

          <div *ngIf="currentLang == 'ar'" class="lang-change-button" (click)="checngeLang('en')"><a >English</a></div>
          <div *ngIf="currentLang == 'en'"  class="lang-change-button" (click)="checngeLang('ar')"><a >Arabic</a></div>

          <ul>
            <li><a href="#"><i class="icn icon-facebook"></i></a></li>
            <li><a href="#"><i class="icn icon-twitter"></i></a></li>
            <li><a href="#"><i class="icn icon-instagram"></i></a></li>
            <li><a href="#"><i class="icn icon-linkedin"></i></a></li>
          </ul>
          <div class="mail"><span href="mailto:info@rubikly.com" >info@rubikly.com</span></div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-3">
        <div class="logo"><a href="#"></a></div>

        <div class="mobile-menu">
          <div class="hamburger hamburger--spring">
						<span class="hamburger-box">
							<span class="hamburger-inner">
							</span>
						</span>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <nav>
          <ul>
            <a routerLink="/"><li><a >Home</a></li></a>
            <a routerLink="/about"><li><a >About</a></li></a>
            <a routerLink="/services"><li><a >Services</a></li></a>
            <a routerLink="/portfolio"><li><a >Portfolio</a></li></a>
            <a routerLink="/faq"><li><a >F.A.Q.</a></li></a>
            <li class="btn" (click)="gotToFreeQuoteSection()"><a>Free Quote</a></li>

          </ul>
        </nav>
      </div>
    </div>

    <div class="mobile-menu-toggle">
      <ul class="main">
        <li class="item"><a> <a to="/home">Home</a> </a></li>
        <li class="item"><a> <a to="/about">About</a> </a></li>
        <li class="item"><a> <a to="/services">Services</a> </a></li>
        <li class="item"><a> <a to="/Portfolio">Portfolio</a> </a></li>
        <li class="item"><a> <a to="/faq">F.A.Q.</a> </a></li>
      </ul>
      <a class="quote" href="#">Free Quote</a>
    </div>
  </div>
</header>
