<ng-container>
  <div>

    <main>
      <section class="breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <h1>About Us</h1>
              <ul class="nav">
                <li><a routerLink="/">Home</a></li>
                <li>About us</li>
              </ul>
            </div>
            <div class="col-md-8">
              <ul class="stats">
                <li>
                  <span>16</span>
                  <span>Sub Head</span>
                </li>
                <li>
                  <span>82</span>
                  <span>Sub Head</span>
                </li>
                <li>
                  <span>24</span>
                  <span>Sub Head</span>
                </li>
                <li>
                  <span>62</span>
                  <span>Sub Head</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="standard-box">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <div class="left">
                <h2>We provide<br> best a-z digital service</h2>
                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word.<br><br>

                  The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
              </div>
            </div>
            <div class="col-md-5">
              <div class="illustration cube-floating-one"></div>
            </div>
          </div>
        </div>
      </section>
      <section class="build-future">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="head">Build your Future!</div>
            </div>
            <div class="col-12 col-lg-7"><p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it.</p></div>
            <div class="col-12 col-lg-2"><div class="button"><a href="#">CTA Button</a></div></div>
          </div>
        </div>
      </section>
      <section class="team">
        <div class="owl-carousel">
          <div class="slider owl-loaded owl-drag">





            <div class="owl-stage-outer"><div class="owl-stage" style="transform: translate3d(-2094px, 0px, 0px); transition: all 0.25s ease 0s; width: 3403px;"><div class="owl-item cloned" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/planer.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div><div class="owl-item cloned" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/manager.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div><div class="owl-item cloned" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/designer.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div><div class="owl-item cloned" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/developer.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div><div class="owl-item" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/founder.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div><div class="owl-item" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/planer.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div><div class="owl-item" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/manager.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div><div class="owl-item" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/designer.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div><div class="owl-item active" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/developer.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div><div class="owl-item cloned active" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/founder.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div><div class="owl-item cloned active" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/planer.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div><div class="owl-item cloned active" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/manager.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div><div class="owl-item cloned" style="width: 261.75px;"><div class="item">
              <div class="image"><img src="assets/img/team/designer.png"></div>
              <div class="name">Roma de Suza, Founder</div>
            </div></div></div></div><div class="owl-nav disabled"><div class="owl-prev">prev</div><div class="owl-next">next</div></div><div class="owl-dots"><div class="owl-dot"><span></span></div><div class="owl-dot active"><span></span></div></div></div>
        </div>
      </section>
      <section class="reference-logos">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="head">
                <h2>Heading Reference Logos</h2>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it.</p>
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="logo"><img src="assets/img/reference/airbnb.png"></div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="logo"><img src="assets/img/reference/sprint.png"></div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="logo"><img src="assets/img/reference/dualingo.png"></div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="logo"><img src="assets/img/reference/stripe.png"></div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="logo"><img src="assets/img/reference/hubspot.png"></div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="logo"><img src="assets/img/reference/aircall.png"></div>
            </div>
          </div>
        </div>
      </section>

      <section class="creative-boxes mbottom">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-6 col-xl-5">
              <div class="left">
                <div class="small-head">What we do?</div>
                <div class="primary-head">Digital marketing agency on a mission</div>
                <div class="button"><a href="#">About us</a></div>
              </div>
            </div>
            <div class="col-md-12 col-lg-3">
              <div class="boxes o-l">
                <div class="item pink">
                  <div class="icon"><div class="circle"><i class="icon-affiliate"></i></div></div>
                  <div class="head">Features Name</div>
                </div>
                <div class="item purple">
                  <div class="icon"><div class="circle"><i class="icon-graphic-design"></i></div></div>
                  <div class="head">Features Name</div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-3">
              <div class="boxes o-r">
                <div class="item blue">
                  <div class="icon"><div class="circle"><i class="icon-coding"></i></div></div>
                  <div class="head">Features Name</div>
                </div>
                <div class="item green">
                  <div class="icon"><div class="circle"><i class="icon-idea"></i></div></div>
                  <div class="head">Features Name</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>







  </div>
</ng-container>
