<section class="content-header">
  <!-- ...header content... -->
</section>

<section class="content">
  <div class="container">


    <section class="content-header">
      <!-- ...header content... -->
    </section>

    <section class="content">
      <div class="container">
        <div class="col-sm-6">
          <h1>Termiologies</h1>
        </div>
        <button pButton type="button" class="btn btn-danger" (click)="openCreateDialog()">Create New</button>

        <p-table [value]="pagesData" dataKey="id" [style]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header">
            <tr>
              <th style="width:25%">id</th>
              <th style="width:25%">key</th>
              <th style="width:25%">value</th>
              <th>Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-pagesData let-ri="rowIndex">
            <tr>
              <td>{{ pagesData?.id }}</td>
              <td>{{ pagesData?.key }}</td>
              <td>{{ pagesData?.value }}</td>
              <td>
                <button pButton type="button" class="btn btn-success" (click)="openEditDialog(pagesData, 'value')">Edit</button>
                <button pButton type="button" class="btn btn-warning" (click)="deleteRecord(pagesData.id)">Delete</button>

              </td>
            </tr>
          </ng-template>
        </p-table>

        <!-- Edit Dialog -->
        <p-dialog header="Edit Value" [(visible)]="displayEditDialog" [styleClass]="'custom-dialog-style'">
          <ng-template pTemplate="content">
            <div class="form-group">
              <label for="edit-key">Key:</label>
              <input id="edit-key" pInputText type="text" [(ngModel)]="selectedItem.key" class="p-inputtext" />
            </div>
            <div class="form-group">
              <label for="edit-value">Edited Value:</label>
              <input id="edit-value" pInputText type="text" [(ngModel)]="selectedItem.editedValue" class="p-inputtext" />
            </div>
          </ng-template>
          <ng-template pTemplate="footer">
            <button pButton type="button" (click)="saveEdit()">Save</button>
            <button pButton type="button" (click)="cancelEdit()" class="p-button-secondary">Cancel</button>
          </ng-template>
        </p-dialog>
      </div>

      <!-- Create Dialog -->
      <p-dialog header="Create New Record" [(visible)]="displayCreateDialog" [styleClass]="'custom-dialog-style'">
        <ng-template pTemplate="content">
          <div class="form-group">
            <label for="create-key">Key:</label>
            <input id="create-key" pInputText type="text" [(ngModel)]="newItem.key" class="p-inputtext" />
          </div>
          <div class="form-group">
            <label for="create-value">Value:</label>
            <input id="create-value" pInputText type="text" [(ngModel)]="newItem.value" class="p-inputtext" />
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <button pButton type="button" (click)="saveCreate()">Save</button>
          <button pButton type="button" (click)="cancelCreate()" class="p-button-secondary">Cancel</button>
        </ng-template>
      </p-dialog>
    </section>

  </div>
</section>
