import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TerminologyService {
  private terminologies = new BehaviorSubject<{ [key: string]: string }>({});
  currentTerminologies = this.terminologies.asObservable();

  constructor(private http: HttpClient) {
    this.loadTerminologies();
  }

  loadTerminologies() {
    this.http.get('http://127.0.0.1:8000/api/pagesData').subscribe((response: any) => {
      const terms: { [key: string]: string } = {};
      response.forEach((item: any) => {
        terms[item.key] = item.value;
      });
      this.terminologies.next(terms);
    });
  }
}
