<div id="app" *ngIf="!encapsulate">


  <app-home-nav></app-home-nav>


  <div >
<!--    <app-loading></app-loading>-->

    <router-outlet></router-outlet>

  </div>


  <app-footer-main-page></app-footer-main-page>



</div>
<div *ngIf="encapsulate">
  <router-outlet></router-outlet>

</div>
